<template>
  <div>
    <section id="card-content-types">
      <h1 class="mt-3">
        Песни {{ lang }}
      </h1>
      <b-row>
        <b-col
            md="12"
            lg="12"
        >
          <!-- table -->
          <div class="d-xl-block d-none">
            <vue-good-table
                class="song-list xs-no"
                :columns="columns"
                :rows="songsList"
                :search-options="{
                enabled: false,
                externalQuery: searchTerm }"
                :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: false, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
                :pagination-options="{
                enabled: false,
                perPage:pageLength
              }"
                :line-numbers="true"
            >
              <template
                  slot="table-row"
                  slot-scope="props"
              >


                <!-- Column: Rating -->
                <span v-if="props.column.field === 'votes'" class="votes-count">
                  <b-badge
                      :variant="(props.row.votes_count > 2) ? 'success' : 'warning'"
                      class="badge-glow"
                  >
                    <feather-icon icon="StarIcon"/>
                    {{ props.row.votes_count }}
                  </b-badge>
                </span>

                <!-- Column: Rating -->
                <span v-if="props.column.field === 'rating_first'">
                  <rating-badge :rating="props.row.rating"></rating-badge>
                </span>

                <!-- Column: Name -->
                <span
                    v-else-if="props.column.field === 'title'"
                >
                  <b-avatar
                      :src="props.row.cover"
                      class="mx-1"
                  />
                  <span class="text-nowrap">{{ props.row.title }}</span>
                </span>

                <!-- Column: Rating -->
                <span v-else-if="props.column.field === 'rating'">
                  <b-form-rating
                      v-model="props.row.rating"
                      show-clear
                      show-value

                      stars="10"
                      variant="warning"
                      size="sm"
                      @change="vote(props.row)"
                  />
                </span>

                <!-- Column: Ya Song -->
                <span v-else-if="props.column.field === 'yandex_song'">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-center
                    variant="outline-primary"
                    @click="currentSong = props.row"
                    v-if="props.row.yandex_id"
                >
                  Послушать
                </b-button>
                <b-link :href="props.row.url" target="_blank" v-else>
                  <feather-icon icon="ExternalLinkIcon"/>
                  Ссылка
                </b-link>
              </span>

                <!-- Column: Release -->
                <span v-else-if="props.column.field === 'release_date'">
                  {{ props.row.release_date | formatDate }}
                </span>


                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                  slot="pagination-bottom"
                  slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      1 to
                    </span>
                    <b-form-select
                        v-model="pageLength"
                        :options="['10','25','100']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
          <div class="d-xl-none">
            <b-table
                :items="songsList"
                :fields="compactFields"
                striped
                responsive
                class="mb-0"
                ref="songsListTable"
                selectable
                :select-mode="selectMode"
                @row-clicked="onRowClicked"
            >
              <!-- full detail on click -->
              <template #row-details="row">
                <iframe frameborder="0" style="border:none;width:100%;height:70px;" width="100%" height="70" v-if="row.item.yandex_id"
                        :src="getYandexIframeUrl(row.item.yandex_id,row.item.yandex_album_id)"></iframe>
                <b-link :href="row.item.url" target="_blank" v-else>
                  <feather-icon icon="ExternalLinkIcon"/>
                  Ссылка
                </b-link>

                <b-form-rating
                    v-model="row.item.rating"
                    show-clear
                    show-value

                    stars="10"
                    variant="warning"
                    size="sm"
                    @change="vote(row.item)"
                />
              </template>

              <template #cell(cover)="data">
                <b-avatar :src="data.cover" />
              </template>

              <template #cell(rating)="data">
                <b-badge
                    :variant="(data.item.votes_count > 2) ? 'success' : 'warning'"
                    class="badge-glow"
                >
                  <feather-icon icon="StarIcon"/>
                  {{ data.item.votes_count }}
                </b-badge>

                <rating-badge :rating="data.item.rating"></rating-badge>
              </template>
            </b-table>
          </div>

        </b-col>
      </b-row>
    </section>

    <b-modal
        id="modal-center"
        centered
        :title="currentSong.title"
        ok-only
        ok-title="Закрыть"
        size="lg"
    >
      <b-card-text>
        <iframe frameborder="0" style="border:none;width:100%;height:70px;" width="100%" height="70"
                :src="getYandexIframeUrl(currentSong.yandex_id,currentSong.yandex_album_id)"></iframe>
        <b-form-rating
            v-model="currentSong.rating"
            show-clear
            show-value

            stars="10"
            variant="warning"
            size="lg"
            @change="vote(currentSong)"
        />
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BCol, BDropdown, BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput, BFormRating,
  BFormSelect, BModal,
  BPagination,
  BRow, BButton, BCardText, BTable,
  BCard,
  BLink,
} from 'bootstrap-vue'
import RatingBadge from '../RatingBadge'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'

/*eslint-disable */
export default {
  components: {
    BCardActions,
    BFormCheckbox,
    BRow,
    BCol,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BBadge,
    BAvatar,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BFormRating,
    RatingBadge,
    BModal,
    BButton,
    BCardText,
    BTable,
    BCard,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentSong: {},
      search_query: '',
      songsList: [],
      currentPage: 1,
      perPage: 1,
      rows: 140,
      pageLength: 25,
      dir: false,
      filter: {
        onlyUnrated: true,
      },
      selectMode: 'single',
      columns: [
        {
          label: 'Оценок',
          field: 'votes',
        },
        {
          label: '✩',
          field: 'rating_first',
        },
        {
          label: 'Название',
          field: 'title',
        },
        {
          label: 'Релиз',
          field: 'release_date',
        },
        {
          label: 'Песня',
          field: 'yandex_song',
          tdClass: 'text-center',
        },
        {
          label: 'Рейтинг',
          field: 'rating',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Фильтр по оценке', // placeholder for filter input
            filterDropdownItems: [
              {value: 0, text: 'Без оценки'},
              {value: 1, text: 'С оценкой'},
            ], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
          },
        },
      ],
      compactFields: [
        { key: 'title', label: 'Название' },
        { key: 'rating', label: 'Рейтинг' },
      ],
      searchTerm: '',
    }
  },
  props: ['lang'],
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
  },
  methods: {
    getYandexIframeUrl(albumId, trackId) {
      return 'https://music.yandex.ru/iframe/#track/' + albumId + '/' + trackId
    },
    onRowClicked(item, index) {
      this.songsList[index]._showDetails = !this.songsList[index]._showDetails
    },
    vote: function (track) {
      this.$http.post('/vote/song',
          {
            rating: track.rating,
            object_id: track.id,
          },
      ).then(res => {
        console.log('ITS OK')
      })
    },
    columnFilterFn(data, filterString) {
      var filterHasRating = (parseInt(filterString) !== 0)
      var rating = parseInt(data)
      if (filterHasRating) {
        return rating > 0
      } else {
        return ((rating <= 0) || isNaN(rating))
      }
    },
  },
  created() {
    this.$http.get('/songs?lang=' + this.lang).then(res => {
      this.songsList = res.data.data
      this.songsList.map(obj=> ({ ...obj, _showDetails: false }))
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>